<template>
  <section class="transfer">
    <div class="heading">
      <h2>Scheduled Transactions</h2>
      <p>All Scheduled and Reoccuring transactions</p>
    </div>

    <el-select
      v-model="form.scheduleType"
      @change="getScheduledPayments"
      clearable
      placeholder="Select Schedule Type"
      style="width:100%;"
    >
      <el-option
        v-for="(category, index) in categories"
        :key="index"
        :label="category.text"
        :value="category.value"
      >
        {{ category.text }}
      </el-option>
    </el-select>

    <div
      class="trasactions__container"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <section
        v-for="transaction in transactions"
        :key="transaction.ScheduleID"
        class="schedule"
      >
        <el-divider
          content-position="left"
          v-if="form.scheduleType === 'scheduled'"
          >{{ transaction.ScheduledDate | formatDate }}</el-divider
        >
        <el-divider
          content-position="left"
          v-if="form.scheduleType === 'reoccuring'"
          >{{ transaction.StartDate | formatDate }}</el-divider
        >
        <div class="schedule__group">
          <div class="schedule__left">
            <h2 class="schedule__name">{{ transaction.ReceiverName }}</h2>
            <h3 class="schedule__type">
              {{ transaction.DestinationBank }} - {{ transaction.ToAccount }}
            </h3>
          </div>
          <div class="schedule__right">
            <h3 class="schedule__amount">
              {{ transaction.Amount | formatCurrency }}
            </h3>
            <div>
              <el-button
                plain
                size="mini"
                class="schedule__button"
                @click="showDetails(transaction)"
                >View</el-button
              >
              <el-button
                type="primary"
                size="mini"
                class="schedule__button"
                @click="cancelTransaction(transaction)"
                >Cancel</el-button
              >
            </div>
          </div>
        </div>
      </section>
    </div>

    <el-dialog
      v-if="modalDetails !== null"
      :visible.sync="showDetailsModal"
      :show-close="false"
      :close-on-click-modal="true"
      style="padding: 0; text-align:left"
      class="dialogue"
    >
      <div class="dialogue__heading">
        <h2>Transaction Details</h2>
        <div class="dialogue__close" @click="showDetailsModal = false">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3 10.0006L19.4873 3.81337C20.1709 3.12974 20.1709 2.02141 19.4873 1.33885L18.6624 0.514004C17.9786 -0.169839 16.8703 -0.169839 16.1877 0.514004L10.0006 6.70106L3.81337 0.512722C3.12974 -0.170907 2.02141 -0.170907 1.33885 0.512722L0.512722 1.33756C-0.170907 2.02141 -0.170907 3.12974 0.512722 3.8123L6.70106 10.0006L0.514004 16.1877C-0.169839 16.8715 -0.169839 17.9799 0.514004 18.6624L1.33885 19.4873C2.02247 20.1709 3.13081 20.1709 3.81337 19.4873L10.0006 13.3L16.1877 19.4873C16.8715 20.1709 17.9799 20.1709 18.6624 19.4873L19.4873 18.6624C20.1709 17.9786 20.1709 16.8703 19.4873 16.1877L13.3 10.0006Z"
              fill="#DB353A"
            />
          </svg>
        </div>
      </div>

      <el-divider><i class="el-icon-document"></i></el-divider>

      <div class="sub-group">
        <h3>Amount:</h3>
        <p>{{ modalDetails.Amount | formatCurrency }}</p>
      </div>
      <div class="sub-group" v-if="form.scheduleType === 'scheduled'">
        <h3>Date:</h3>
        <p>{{ modalDetails.ScheduledDate | formatDate }}</p>
      </div>
      <div class="sub-group" v-if="form.scheduleType === 'reoccuring'">
        <h3>Start Date:</h3>
        <p>{{ modalDetails.StartDate | formatDate }}</p>
      </div>
      <div class="sub-group" v-if="form.scheduleType === 'reoccuring'">
        <h3>End Date:</h3>
        <p>{{ modalDetails.EndDate | formatDate }}</p>
      </div>
      <div class="sub-group" v-if="form.scheduleType === 'reoccuring'">
        <h3>Frequency:</h3>
        <p>{{ setFrequency(modalDetails.Frequency) }}</p>
      </div>

      <el-divider content-position="left">From</el-divider>
      <div class="sub-group">
        <h3>Account Number:</h3>
        <p>{{ modalDetails.FromAccount }}</p>
      </div>
      <div class="sub-group">
        <h3>Currency:</h3>
        <p>{{ modalDetails.DebitCurrency }}</p>
      </div>

      <el-divider content-position="left">To</el-divider>
      <div class="sub-group">
        <h3>Name:</h3>
        <p>{{ modalDetails.ReceiverName }}</p>
      </div>
      <div class="sub-group">
        <h3>Account Number:</h3>
        <p>{{ modalDetails.ToAccount }}</p>
      </div>
      <div class="sub-group">
        <h3>Currency:</h3>
        <p>{{ modalDetails.CreditCurrency }}</p>
      </div>
      <div class="sub-group">
        <h3>Bank:</h3>
        <p>{{ modalDetails.DestinationBank }}</p>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from "@/api/api.js";

import { mapState } from "vuex";
export default {
  computed: mapState(["userAccounts", "user"]),
  data() {
    return {
      loading: false,
      transactions: [],
      modalDetails: null,
      showDetailsModal: false,
      form: {
        scheduleType: ""
      },
      categories: [
        { text: "Scheduled Transactions", value: "scheduled" },
        { text: "Reoccuring Transactions", value: "reoccuring" }
      ]
    };
  },
  methods: {
    getScheduledPayments() {
      let payload = {
        smId: this.user.profileInfo.SMID
      };

      if (this.form.scheduleType === "scheduled") {
        this.loading = true;

        api
          .getPendingScheduledPayment(payload)
          .then(response => {
            this.loading = false;
            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              let transactions = response.Data;

              if (transactions.length <= 0) {
                this.$message({
                  showClose: true,
                  message: `No records found.`,
                  type: "error",
                  duration: 5000
                });
                return;
              }

              // let lastTwentyTransactions = transactions.slice(0, 20);
              this.transactions = transactions;
            } else {
              this.$message({
                showClose: true,
                message: `${responseMessage.charAt(0).toUpperCase() +
                  responseMessage.slice(1)}`,
                type: "error",
                duration: 10000
              });
            }
          })
          .catch(error => {
            this.loadingThree = false;
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000
            });
          });
      } else if (this.form.scheduleType === "reoccuring") {
        this.loading = true;

        api
          .getPendingRecurringPayment(payload)
          .then(response => {
            this.loading = false;
            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              let transactions = response.Data;

              if (transactions.length <= 0) {
                this.$message({
                  showClose: true,
                  message: `No records found.`,
                  type: "error",
                  duration: 5000
                });
                return;
              }

              // let lastTwentyTransactions = transactions.slice(0, 20);
              this.transactions = transactions;
            } else {
              this.$message({
                showClose: true,
                message: `${responseMessage.charAt(0).toUpperCase() +
                  responseMessage.slice(1)}`,
                type: "error",
                duration: 10000
              });
            }
          })
          .catch(error => {
            this.loadingThree = false;
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000
            });
          });
      }
    },

    showDetails(value) {
      this.modalDetails = value;
      this.showDetailsModal = true;
    },
    setFrequency(value) {
      let valueWord;
      switch (value) {
        case "1":
          valueWord = "Daily";
          break;
        case "2":
          valueWord = "Weekly";
          break;
        case "3":
          valueWord = "Monthly";
          break;

        default:
          valueWord = value;
          break;
      }

      return valueWord;
    },
    cancelTransaction(value) {
      if (this.form.scheduleType === "scheduled") {
        let payload = {
          ScheduleID: value.ScheduleID,
          smId: this.user.profileInfo.SMID
        };

        this.loading = true;

        api
          .cancelScheduledPayment(payload)
          .then(response => {
            this.loading = false;

            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              this.$message({
                showClose: true,
                message: `${responseMessage.charAt(0).toUpperCase() +
                  responseMessage.slice(1)}`,
                type: "success",
                duration: 10000
              });
              this.form.scheduleType = "scheduled";
              this.getScheduledPayments();
            } else {
              this.$message({
                showClose: true,
                message: `${responseMessage.charAt(0).toUpperCase() +
                  responseMessage.slice(1)}`,
                type: "error",
                duration: 10000
              });
            }
          })
          .catch(error => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000
            });
          });
      } else if (this.form.scheduleType === "reoccuring") {
        let payload = {
          PaymentID: value.PaymentID,
          smId: this.user.profileInfo.SMID
        };

        this.loading = true;

        api
          .cancelRecurringPayment(payload)
          .then(response => {
            this.loading = false;

            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              this.$message({
                showClose: true,
                message: `${responseMessage.charAt(0).toUpperCase() +
                  responseMessage.slice(1)}`,
                type: "success",
                duration: 10000
              });
              this.form.scheduleType = "reoccuring";
              this.getScheduledPayments();
            } else {
              this.$message({
                showClose: true,
                message: `${responseMessage.charAt(0).toUpperCase() +
                  responseMessage.slice(1)}`,
                type: "error",
                duration: 10000
              });
            }
          })
          .catch(error => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000
            });
          });
      }
    }
  },
  mounted() {
    this.form.scheduleType = "scheduled";
    this.getScheduledPayments();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
